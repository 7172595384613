<template>
  <div class="alert alert-danger" v-if="errors.length">
    <ul class="mb-0">
      <li v-for="error in errors" :key="error">
        {{ error }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
    },
  },
};
</script>
