<template>
  <onboarding-layout back-route-name="Login">
    <div class="login-container">
      <h2 class="mb-3 mb-lg-5">
        Wachtwoord veranderen
      </h2>

      <form class="form-block" @submit.prevent="requestPassword">
        <Errors :errors="errors" />

        <div class="form-group mb-2">
          <label for="email" class="visually-hidden">
            E-mailadres
          </label>

          <input
            class="form-control form-control--center-placeholder"
            type="email"
            id="email"
            name="email"
            v-model="formInput.email"
            placeholder="Vul hier je mailadres in"
          />
        </div>

        <Button
          button-type="submit"
          :disabled="requestResetPasswordLoadingState === 'loading'"
        >
          {{ requestResetPasswordLoadingState === 'loading'
            ? 'Een moment geduld... '
            : 'Wachtwoordverzoek versturen '
          }}
        </Button>
      </form>
    </div>
  </onboarding-layout>
</template>

<script>
import Errors from '../../components/Errors.vue';
import retrieveErrorsFromResponse from '../../helpers/retrieveErrorsFromResponse';
import OnboardingLayout from '../Onboarding/OnboardingLayout.vue';
import Button from '../../components/Button.vue';

export default {
  name: 'PasswordReset',

  components: {
    Button,
    OnboardingLayout,
    Errors,
  },

  data() {
    return {
      formInput: {
        email: '',
      },
      errors: [],
    };
  },

  mounted() {
    const { query } = this.$route;

    this.formInput.email = query.email;
  },

  methods: {
    requestPassword() {
      this.errors = [];

      if (!this.validate()) {
        return;
      }

      this.$store.dispatch('requestResetPassword', this.formInput)
        .then(() => {
          this.$store.commit('setFlashMessage', [
            `
              We hebben je een mail gestuurd met informatie voor het
              resetten van je wachtwoord.
            `,
            'success',
          ]);

          this.$router.push({ name: 'Login' });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);

          if (!err.response) {
            this.errors = ['Er ging iets mis. Probeer het later opnieuw.'];
            return;
          }

          this.errors = retrieveErrorsFromResponse(err.response);
        });
    },

    validate() {
      if (!this.formInput.email) {
        this.errors.push('Voer een e-mailadres in');
      }

      return this.errors.length === 0;
    },
  },

  computed: {
    requestResetPasswordLoadingState() {
      return this.$store.getters.requestResetPasswordLoadingState;
    },
  },
};
</script>

<style scoped>
.login-container {
  max-width: 25rem;
  margin: 0 auto;
}
</style>
